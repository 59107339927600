.actionButton {
    border-radius: 4px;
    font-size: 17px;
    transition: all 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
    color: whitesmoke;
    text-shadow: 1px 1px 3px rgb(0 0 0 / 50%);
    transition: all 0.1s ease-in;
}

.default {
    background: linear-gradient(0deg, #b16c37 10%, #cc8a4d 100%);

    &:hover {
        background: linear-gradient(0deg, #be7741 10%, #e69b55 100%);
    }

    &:active {
        background: linear-gradient(0deg, #7a4a25 10%, #b17742 100%);
    }
}

.red {
    background: linear-gradient(0deg, #8d1c21 10%, #bf2e32 100%);

    &:hover {
        background: linear-gradient(0deg, #b11a22 10%, #ec2d34 100%);
    }

    &:active {
        background: linear-gradient(0deg, #80171c 10%, #b4262b 100%);
    }
}

.lore {
    background: linear-gradient(0deg, #3b1d0e 10%, #623723 100%);

    &:hover {
        background: linear-gradient(0deg, #452313 10%, #774127 100%);
    }

    &:active {
        background: linear-gradient(0deg, #542b19 10%, #653722 100%);
    }
}

.blue {
    background: linear-gradient(0deg, rgb(13, 107, 201) 10%, rgb(20, 126, 232) 100%);

    &:hover {
        background: linear-gradient(0deg, rgb(22, 125, 229) 10%, rgb(31, 142, 253) 100%);
    }

    &:active {
        background: linear-gradient(0deg, rgb(11, 94, 178) 10%, rgb(15, 113, 211) 100%);
    }
}

.orange {
    background: linear-gradient(0deg, #d36505 10%, #f5821d 100%);

    &:hover {
        background: linear-gradient(0deg, #f3770a 10%, #f98f30 100%);
    }

    &:active {
        background: linear-gradient(0deg, #c05b03 10%, #e7720c 100%);
    }
}

.green {
    background: linear-gradient(0deg, #007e28 10%, #0bb140 100%);

    &:hover {
        background: linear-gradient(0deg, #019630 10%, #0cc948 100%);
    }

    &:active {
        background: linear-gradient(0deg, #037a29 10%, #0ea13c 100%);
    }
}

.disabled {
    background: gray;
    box-shadow: inset 0 0 5px rgb(0 0 0 / 25%);

    &:hover {
        background: gray;
    }
}

.loading {
    pointer-events: none;
    user-select: none;
}
