@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;600&display=swap');

$scrollbar-thumb-color: #d29835;
$scrollbar-track-color: #d4d4d466;

body {
    margin: 0;
    min-width: 100vw;
    color: whitesmoke;
    overflow: auto;
}

#root {
    min-width: 100vw;
    min-height: 100vh;
}

// Used for NavLinks
.Header-Menu {
    .active {
        p {
            color: whitesmoke;
            text-shadow: 0 0 10px white;
        }
    }
}

.Second-Header-Menu {
    .active {
        .Border-Box {
            border-bottom: 2px solid whitesmoke;

            p {
                color: whitesmoke;
            }
        }
    }
}

.Fade-In {
    transition: all 0.35s ease-in;
}

.NFT {
    .NFT-Overlay {
        transition: all 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    .Selected-NFT {
        transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    &:hover {
        .NFT-Overlay {
            background-color: #0000004f;
        }

        .Selected-NFT {
            filter: brightness(1.25);
        }
    }
}

// Checkbox
.chakra-checkbox__control {
    box-shadow: none !important;
}

.Detailed-Quest-Checkbox {
    .chakra-checkbox__label {
        width: 100%;
    }
}

// Switch
.chakra-switch__track {
    box-shadow: none !important;
}

// Mvx
.Login-Button {
    width: 100%;
    margin: 0 !important;
    padding: 18px 18px !important;
    border: 0 !important;
    outline: none !important;
    background-color: #7638ac !important;

    &:hover {
        background-color: #8830d5 !important;
    }

    &:focus {
        box-shadow: 0 0 0 0.15rem #8a27e066 !important;
    }

    &:not(:last-child) {
        margin: 0 0 12px !important;
    }
}

// Dapp Sign Tx Modal
.Sign-Tx-Modal {
    .dapp-modal-dialog-content-body {
        background-color: #222222 !important;
    }

    .dapp-modal-dialog-content {
        border: none !important;
    }
}

// Dapp Tx Toasts
@media (min-width: 768px) {
    .Tx-Toast {
        right: 1rem !important;
        bottom: 1rem !important;
    }
}

.Tx-Toast {
    font-family: 'IBM Plex Mono', monospace !important;
    padding: 0.75rem 0.5rem !important;
    background-color: #222222 !important;
    overflow: hidden;

    .dapp-core-component__transactionToast-styles__heading {
        margin-bottom: 0.5rem;
    }

    .dapp-core-component__transactionDetails-styles__status {
        font-size: 14px;
        font-weight: 600;
        margin: 0.5rem 0 0.5rem;
    }

    .dapp-core-component__transactionDetails-styles__container {
        margin-bottom: 0;
    }

    h5 {
        font-size: 17px;
        margin-bottom: 0;
        font-weight: 600;
    }

    .dapp-core-component__progressStyles__progress .dapp-core-component__progressStyles__bar {
        top: -0.75rem;
        bottom: -0.75rem;
    }

    .dapp-core-component__transactionToast-styles__close {
        color: #fff !important;
    }
}

.dapp-core-component__transactionToast-styles__content
    .dapp-core-component__transactionToast-styles__left
    .dapp-core-component__transactionToast-styles__icon
    .Custom-Error-Toast-Icon {
    background: #d9534f;
}

.dapp-failed-transaction-status-toast {
    font-family: 'IBM Plex Mono', monospace !important;
    font-size: 15px;
    font-weight: 600;

    .dapp-core-component__customToast-styles__close {
        color: #fff !important;
    }

    .dapp-core-component__transactionsToastList-styles__toastWrapper {
        background-color: #222222 !important;
        margin-bottom: 0 !important;
    }
}

.dapp-custom-toast {
    font-family: 'IBM Plex Mono', monospace !important;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.75rem 0.5rem !important;
    background-color: #222222 !important;
    margin-bottom: 0 !important;

    h5 {
        font-size: 17px;
        margin-bottom: 0;
        font-weight: 700;
    }

    .dapp-core-component__transactionToast-styles__content
        .dapp-core-component__transactionToast-styles__left
        .dapp-core-component__transactionToast-styles__icon {
        background: pink;
    }

    .dapp-core-component__customToast-styles__close {
        color: #fff !important;
    }
}

// Mvx Modals
.dapp-core-component__dappModalStyles__dappModalContent {
    background-color: #222222 !important;
}

.dapp-core-component__main__btn-light {
    color: #868686 !important;
    background-color: #222222 !important;
}

.dapp-core-component__dappModalStyles__dappModal {
    z-index: 9999 !important;
}

.dapp-core-component__walletConnectLoginContainerStyles__xPortal-pairing-button {
    background-color: #b0cbe5 !important;
    color: black !important;
    font-weight: 500 !important;
}

// Scrollbar
.Scrollbar-Gutter {
    scrollbar-gutter: stable;
}

/* Firefox */
* {
    scrollbar-width: none;
    scrollbar-color: $scrollbar-thumb-color $scrollbar-track-color;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 6px;
}

*::-webkit-scrollbar-track {
    background: $scrollbar-track-color;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    background-clip: padding-box;
}

*::-webkit-scrollbar-thumb {
    background-color: $scrollbar-thumb-color;
    border-radius: 0px;
    border: 0;
}

// Animations
@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

@keyframes rotationReverse {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-359deg);
    }
}

// Chakra Menu
// .chakra-menu__group__title {
//     background-color: #0000001a;
//     margin: 0 !important;
//     padding: 0.5rem 1rem 0.5rem 1rem;
// }

.dapp-core-component__transactionsToastList-styles__toasts .dapp-core-component__transactionsToastList-styles__toastWrapper {
    margin-right: 1rem !important;
}
